@font-face {
  font-family: 'Superstar';
  src: url(superstar.ttf);
}

body {
  background: black;
  position: relative;
}

body, html {
  overflow: auto;
  width: 100%;
  min-width: 1230px;
  margin: 0;
  font-family: 'Superstar', monospace;
  color: white;
  line-height: 1.5em;
}

b {
  font-weight: normal;
}
