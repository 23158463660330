@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.App {
  width: 1230px;
  margin: auto;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

.App p {
  color: white;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  line-height: 2em;
  font-size: 28px;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

h1 {
  margin: 2em 0;
}

h1 a span {
  display: none;
}

h1 a {
  display: block;
  width: 154px;
  height: 74px;
  background: url(logo-cd.png);
}

nav ul {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
}

nav ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Roboto Mono';
}

nav ul li a:visited,
nav ul li a {
  display: block;
  margin: 0 20px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

nav ul li a::after {
  content: '_';
}

.App .intro {
  width: 100%;
  font-size: 24px;
  line-height: 1.25em;
}

.App p.intro {
  box-sizing: border-box;
}

.intro b {
  color: #96f64a;
}

#stage-1, #stage-3 {
  min-width: 320px;
  max-width: 320px;
}

#stage-2 {
  margin-left: 20px;
  border-left: 2px dotted #d4d4d4;
  border-right: 2px dotted #d4d4d4;
}

.stages {
  display: flex;
}

.stages div {
  font-size: 22px;
  padding: 20px;
}

#chart {
  box-sizing: border-box;
  width: 1200px;
  margin: 20px;
  display: flex;
}

#left, #right, #middle {
  box-sizing: border-box;
  padding: 10px;
  background: #333;
}

#left {
  text-align: center;
  position: relative;
}

#left, #right {
  width: 320px;
}

#right {
  padding: 0;
}

#middle {
  flex-grow: 3;
  margin: 0 20px;
  padding: 0;
  overflow: hidden;
}

.tabs {
  display: flex;
}

.tabs div {
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
  background: black;
  color: #333;
  padding: 10px;
  cursor: pointer;
}

.tabs div.active {
  background: #333;
  color: white;
}

.place {
  font-size: 28px;
  color: white;
}

.total {
  font-size: 96px;
  line-height: 100px;
  color: #96f64a;
}

.subtitle {
  font-size: 28px;
  color: white;
}

.data-label {
  text-transform: uppercase;
  margin: 10px 0;
  padding: 10px 0;
  color: white;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  font-size: 28px;
}

.bar-row {
  text-align: left;
  padding: 10px 0;
}

.bar-row-label {
  font-size: 24px;
}

.bar-row-value {
  font-size: 48px;
  color: #96f64a;
  margin-top: 5px;
}

.bar-row-label small {
  color: #666;
}

.bar-outer {
  width: 100%;
  background: #111;
  height: 32px;
  position: relative;
}

.bar-inner {
  background: #96f64a;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
}

#map-container {
  min-height: 780px;
  margin-top: 10px;
}

.App .map-sentence {
  text-align: center;
  font-size: 20px;
}

.map-sentence b {
  font-size: 32px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.close img {
  cursor: pointer;
}

.icon-cell {
  width: 95px;
  min-height: 105px;
  text-align: center;
  background: #222;
  margin: 2.5px;
}

.icon-cell-number {
  font-size: 32px;
  line-height: 48px;
  color: #96f64a;
  margin-left: 5px;
}

.icon-cell-icon {
  height: 32px;
  width: 32px;
  background: transparent center center no-repeat;
}

.icon-cell-label {
  color: white;
  font-size: 14px;
}

.right-bar-title {
  color: #CCC;
  font-size: 18px;
  border-color: #CCC;
  text-align: center;
}

#icon-video { background-image: url(icon-video.png); }
#icon-person { background-image: url(icon-person.png); }
#icon-paper { background-image: url(icon-paper.png); }
#icon-content { background-image: url(icon-content.png); }
#icon-calendar { background-image: url(icon-calendar.png); }
#icon-check { background-image: url(icon-check.png); }
#icon-chart { background-image: url(icon-chart.png); }
#icon-slide { background-image: url(icon-slide.png); }
#icon-image { background-image: url(icon-image.png); }
#icon-play { background-image: url(icon-play.png); }
#icon-music { background-image: url(icon-music.png); }
#icon-thread { background-image: url(icon-thread.png); }
#icon-quiz { background-image: url(icon-quiz.png); }
#icon-game { background-image: url(icon-game.png); }
#icon-comic { background-image: url(icon-comic.png); }
#icon-site { background-image: url(icon-site.png); }
#icon-other { background-image: url(icon-other.png); }
#icon-location { background-image: url(icon-location.png); }
#icon-time { background-image: url(icon-time.png); }
#icon-student { background-image: url(icon-student.png); }
#icon-teacher { background-image: url(icon-teacher.png); }
#icon-live { background-image: url(icon-live.png); }
#icon-closed { background-image: url(icon-closed.png); }
#icon-saved { background-image: url(icon-saved.png); }

.evaluation {
  padding: 20px;
}

.evaluation .bar-row-label {
  font-size: 18px;
}

.quote::before {
  content: "“";
  font-size: 64px;
  display: block;
}

.quote::after {
  content: "”";
  font-size: 64px;
  display: block;
  margin-top: 20px;
  margin-right: 20px;
  float: right;
}

.quote {
  font-style: italic;
  line-height: 1em;
}

.awssld {
  margin-top: 16px;
}

.awssld__container {
  height: 280px;
}

.awssld__content {
  padding: 10px;
}

.awssld__timer {
  background-color: #96f64a;
}

.awssld__bullets button {
  background: transparent url(slider-pager.png) center center no-repeat;
}

.awssld__bullets button:hover,
.awssld__bullets .awssld__bullets--active:hover {
  transform: scale(1.0);
}

.awssld__bullets .awssld__bullets--active {
  transform: scale(1.0);
  background-image: url(slider-pager-current.png);
}

.awssld__bullets {
  bottom: -23px;
}

.icon-highlight div {
  text-align: center;
}

.icon-highlight .icon-cell-icon {
  width: 100%;
  margin-top: 10px;
}

.icon-highlight .icon-cell-number {
  font-size: 28px;
}

.icon-highlight .icon-cell-label {
  font-size: 16px;
}

.icon-highlight {
  height: 140px;
}

#ambassadors-quotes .awssld__container {
  height: 450px;
}

.ambassador-card-row {
  font-size: 20px;
  text-align: left;
  margin: 10px 0;
}

.ambassador-card-value {
  color: #96f64a;
}

.ambassador-play {
  margin-top: 10px;
}

#years {
  width: 100%;
  display: flex;
}

#years div {
  text-align: center;
  padding: 20px;
  margin: 20px;
  font-size: 24px;
  flex-grow: 1;
  border: 2px dotted #333;
  color: #333;
  cursor: pointer;
}

#years div.selected {
  border-color: white;
  color: white;
}

#loading {
  margin: 20px;
  padding: 20px;
  text-align: center;
  border: 2px dotted #333;
  font-size: 48px;
  color: #333;
  box-sizing: border-box;
}
